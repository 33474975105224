import React from "react";
import CustomNavbar from "../components/CustomNavbar";
import FooterTwo from '../components/Footer/FooterTwo';
import FooterThree from '../components/Footer/FooterThree';
import FooterData from '../components/Footer/FooterData';
const ServerError = () => (
  <div className="body_wrapper">
    <CustomNavbar slogo="sticky_logo" hbtnClass="new_btn" />
    <section className="error_two_area" style={{marginTop: '30px'}}>
      <div className="container flex">
        <div className="error_content_two text-center">
          <img src={require("../img/new/500Error.png")} alt="" />
          <h2>Sorry, Unexpected error.</h2>
          <p>
           We are working on fixing the problem, mean while visit our home page instead...{" "}
          </p>
          {/* <form action="#" className="search">
            <input type="text" className="form-control" placeholder="search" />
          </form> */}
          <a href="/" className="about_btn btn_hover">
            Back to Home Page <i className="arrow_right"></i>
          </a>
        </div>
      </div>
    </section>
    <FooterThree FooterData={FooterData} />
  </div>
);
export default ServerError;
