import React from 'react';
import CustomNavbar from '../components/CustomNavbar';
import PaymentBanner from '../components/Banner/PaymentBanner';
import PaymentFeatures from '../components/Features/PaymentFeatures';
import PaymentService from '../components/Service/PaymentService';
import ServiceData from '../components/Service/ServiceData';
import Paymentprocess from '../components/Paymentprocess';
import PaymentTestimonial from '../components/Testimonial/PaymentTestimonial';
import FooterThree from '../components/Footer/FooterThree';
import FooterData from '../components/Footer/FooterData';
import HRBanner from '../components/Banner/HRBanner';
import Video from '../components/Video';
import HRService from '../components/Service/HRService';
import MarketingGetstarted from '../components/MarketingGetstarted';
import PrototypeFooter from '../components/Footer/PrototypeFooter';

const Paymentprocessing = () => {
    return(
        <div className="body_wrapper">
            <CustomNavbar slogo="sticky_logo" mClass="menu_four" nClass="w_menu"/>
            <HRBanner />
            <Video />
            <HRService ServiceData={ServiceData}/>
            <MarketingGetstarted />
            <Paymentprocess/>
            {/* <PaymentTestimonial/> */}
            <FooterThree FooterData={FooterData}/>
            {/* <PrototypeFooter rclass={'payment_footer_area_two'} FooterData={FooterData}/> */}
        </div>
    )
}
export default Paymentprocessing;